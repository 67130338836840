import * as React from "react";

//Get data

const Product = ({ props }) => {
  return (
    <React.Fragment>
      <p>{props}</p>
      <p>and the price</p>
    </React.Fragment>
  );
};

// Markup
const CartPage = () => {
  return (
    <React.Fragment>
      <h1>This is the chekout</h1>
      <Product props="test" />
    </React.Fragment>
  );
};

export default CartPage;
